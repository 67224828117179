.task-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
}
.close-icon {
  height: 32px;
  width: 32px;
  position: absolute;
  right: 16px;
  top: 16px;
}
.dialog-paper {
  /* background-color: #efeeee; */
  border-radius: 8px;
  min-height: 90vh;
}
.detail-header {
  min-height: 32px;
  padding: 8px 52px 8px 56px;
  position: relative;
  z-index: 1;
}
.textarea-wrapper {
  padding: 12px 0 0;
}
.task-title {
  overflow: hidden;
  overflow-wrap: break-word;
  border-radius: 4px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: -6px -10px;
  min-height: 24px;
  padding: 6px 10px;
  resize: none;
  border: none;
  /* height: 445.333px; */
  height: 100%;
  width: 100%;
}
.task-title:focus {
  background-color: #fff;
  border: 2px solid #00ab55;
  padding: 3px 8px 2px 8px;
  background-color: #f5f5f5;
}
.window-main-col {
  float: left;
  margin: 0;
  min-height: 24px;
  padding: 0 8px 8px 16px;
  position: relative;
  z-index: 1;
}
.no-description-div {
  /* display: inline-block; */
  font-weight: 500;
  background-color: #f5f5f5;
  border-radius: 3px;
  min-height: 60px;
  padding: 1em;
}
.no-description-div:hover {
  /* display: inline-block; */
  font-weight: 500;
  background-color: #f1f1f1;
  border-radius: 3px;
  min-height: 60px;
  padding: 1em;
  cursor: pointer;
}
.comment-name {
  font-weight: 600;
}
.comment-data {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.edit-delete-button {
  text-decoration: underline;
  cursor: pointer;
}
.sidebar-button {
  background-color: #f5f5f5;
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: 600;
  justify-content: space-between;
}
.sidebar-button:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.add-comment-field:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}
.checklist-item-title {
  overflow: hidden;
  overflow-wrap: break-word;
  border-radius: 4px;
  box-shadow: none;
  min-height: 40px;
  padding: 6px 10px;
  resize: none;
  border: none;
  width: 100%;
  border: 1px solid #00ab55;
}
.checklist-item-title:focus {
  border: 2px solid #00ab55;
  padding: 3px 8px 2px 8px;
}
.check-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  min-height: 32px;
}
.check-list-item:hover {
  background-color: #f5f5f5;
}
.edit-checklist-div {
  background-color: #f5f5f5;
}

.ps-2.ms-1.d-flex.justify-content-between.check-list-item.w-100:hover {
  position: relative;
}
.ps-2.ms-1.d-flex.justify-content-between.check-list-item.w-100:hover .hiddenDiv {
  display: block;
}
.hiddenDiv {
  display: none;
  position: absolute;
  right: 5px;
}
.react-datepicker-popper {
  z-index: 1400 !important;
}
.sidebar-button
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiAvatarGroup-avatar.css-1b6xdos-MuiAvatar-root-MuiAvatarGroup-avatar {
  height: 25px !important;
  width: 25px !important;
}
.comment-data p {
  margin: 0 !important;
}
.comment-time {
  font-size: 12px;
  margin-left: 10px;
}

.sidebar-button .css-t71pop {
  height: 25px !important;
  width: 25px !important;
}
