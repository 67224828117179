@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --theme-selected-bg-color: rgb(235, 248, 242);
  --clr-green: #00ab55;
  --theme-primary-clr: #00ab55;
}

.theme-primary-txt {
  color: var(--theme-primary-clr);
}

.theme-primary-bg {
  background-color: var(--theme-primary-clr);
}

html,
body {
  scroll-behavior: auto !important;
}

.no-data-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.view-all-tasks-box {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 24px;
}

.highlight-task {
  transition: all 0.2s ease-in-out;
  outline: solid 3px rgb(0, 171, 85);
  border-radius: "3px";
}

.img-upload-btn {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  bottom: 0px;
  right: -7px;
  border: solid 1px rgba(128, 128, 128, 0.377);
}

.project-name-btn {
  color: rgb(0, 171, 85);
  font-weight: 500;
  background-color: rgb(246, 247, 248);
  padding: 10px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.task-date {
  color: rgb(0, 171, 85);
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.react-datepicker__time-container {
  width: 90px !important;
}

.react-datepicker__time-box {
  width: 90px !important;
}

.no-task-files {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clr-green {
  color: var(--clr-green);
}

.role-bar {
  position: absolute;
  z-index: 3;
  border-radius: 0.3rem;
  bottom: 140px;
  right: 16px;
  cursor: pointer;
}

.status-bar {
  position: absolute;
  z-index: 3;
  border-radius: 0.3rem;
  bottom: 140px;
  left: 16px;
  cursor: pointer;
}

.task-card-menu-btn {
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
  z-index: 2;
}

.vrf-task-card-menu-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 2;
}

.drag-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  z-index: 2;
}

.is-dragging {
  opacity: 40%;
  background-color: #68ffa7;
}

.search-bar {
  position: sticky;
  top: 12px;
  background-color: white;
  z-index: 3;
  padding-top: 5px;
  padding-bottom: 0px;
}

.project-team-update-btn {
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.project-header {
  border: 1px solid #c5c5c5 !important;
  border-radius: 10px !important;
  padding: 3px !important;
}

.project-header:active {
  border: 1px solid #c5c5c5 !important;
  border-radius: 10px !important;
}

.project-header-btn {
  box-shadow: none !important;
  background-color: #f6f7f8 !important;
  color: black !important;
  font-weight: 600;
}

.active-border {
  border: 1px solid #c5c5c5 !important;
}

.active-border:active {
  border: 1px solid #c5c5c5 !important;
}

.progress-box:active {
  border: 1px solid #c5c5c5 !important;
}

.task-type-btn {
  border: solid 1px rgba(0, 171, 85, 0.5) !important;
  border-color: rgba(0, 171, 85, 0.5) !important;
}

.task-type-btn:active {
  border: solid 1px rgba(0, 171, 85, 0.5) !important;
  border-color: rgba(0, 171, 85, 0.5) !important;
}

.add-task-img-parent {
  position: relative;
  width: max-content;
}

.add-task-img {
  position: relative;
  object-fit: cover;
  width: 4rem;
  height: 4rem;
}

.add-task-img-remove {
  position: absolute;
  top: 0px;
  right: 0px;
}

.comment-loader-wrap {
  min-width: 100%;
  min-height: 30rem;
  position: relative;
}

.task-files-container {
  position: relative;
  /* display: flex; */
  /* align-items: center; */
}

.del-task-file-btn {
  top: 0px;
  position: absolute;
  right: 0px;
  z-index: 3;
}

.task-section-parent {
  border: 1px solid #c5c5c5 !important;
  border-radius: 5px;
  position: relative !important;
}

.task-section-parent .MuiAccordionSummary-expandIconWrapper {
  margin-right: 4px !important;
}

.task-section-parent:active {
  border: 1px solid #c5c5c5 !important;
}

/* #task-date-picker .MuiButtonBase-root MuiIconButton-root  MuiIconButton MuiButtonBase */
#task-date-picker .MuiButtonBase-root {
  padding: 0px !important;
}

.outline-btn {
  border: 1px solid #c5c5c5 !important;
}

.outline-btn:active {
  border: 1px solid #c5c5c5 !important;
}

.comment-option-bar {
  position: absolute;
  right: 3px;
}

/* mui overrides */

.avatar-container .MuiAvatarGroup-root .MuiAvatar-root {
  width: 29px;
  height: 29px;
  cursor: pointer;
}

/* utility classes */
.border {
  border: 1px solid #c5c5c5 !important;
}

.pointer {
  cursor: pointer !important;
}

.centered-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.centered-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadow-sm {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.shadow-md {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.shadow-lg {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.hidden {
  display: none !important;
}

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}

/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.tox-notifications-container {
  display: none !important;
}

.fc-daygrid-event {
  overflow: hidden !important;
}

.fc-header-toolbar {
  /* display: none !important; */
}

.today-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.4em 0.65em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.2em;
  border: none;
  background-color: #00ab55;
  color: #ffffff;
}

.custom-button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  margin-left: 10px;
}

.custom-button-group2 {
  margin-left: 0px !important;
}

.custom-button-group > .custom-btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-button-group > .custom-btn:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-btn {
  position: relative;
  flex: 1 1 auto;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 0.4em 0.65em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
  -webkit-appearance: button;
  overflow: visible;
  font-family: inherit;
  cursor: pointer;
  user-select: none;
}

.custom-btn:disabled {
  opacity: 0.65;
}

.custom-btn-primary {
  color: white !important;
  background-color: #00ab55 !important;
  border: none !important;
  margin: 5px !important;
  text-transform: capitalize !important;
}

.custom-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
  user-select: none;
  font-family: "fcicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.custom-btn .custom-icon {
  vertical-align: middle;
  font-size: 1.5em;
}

.custom-icon-chevron-right::before {
  content: "\e901";
}

.custom-icon-chevron-left::before {
  content: "\e900";
}

.custom-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 129.03% !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: -0.01em !important;
  color: #000;
}

.custom-header {
  margin-bottom: 1.5em;
}

.fc-header-toolbar {
  display: none !important;
}

p img {
  object-fit: cover;
  height: 100%;
}

.tox-notifications-container {
  visibility: hidden !important;
}

.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart.css-1eivsbx-MuiInputBase-input-MuiOutlinedInput-input {
  margin-bottom: 5px;
}

.payment-card ul {
  margin-left: 0px !important;
}

.payment-card ul li p {
  margin-bottom: 5px;
}

.payment-card ul li:last-child {
  margin-bottom: 10px;
}

.payment-card ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-alert {
  padding: 10px 16px;
}

.price-alert .MuiAlert-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.ribbon-2 {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */
  --t: -1px;
  /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 5px 5px 13px 20px;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #cece15;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

.plan-information p {
  color: #fff;
}

.plan-information ul li {
  font-size: 13px;
}

.plan-information ul li:not(:last-child) {
  margin-bottom: 5px;
}

.custom-tabs
  .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  border-bottom: 1px solid lightgrey;
}

.custom-tabs
  .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1581pdz-MuiButtonBase-root-MuiTab-root {
  font-size: 12px;
  font-weight: 600;
  /* border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: none; */
  /* background-color: #00ab55;
  color: #fff; */
}

.x-gutter-0 {
  --bs-gutter-x: 0;
}

.inactive-view {
  background-color: #fff !important;
  border: 1px solid #00ab55 !important;
  color: #00ab55 !important;
}

.template-card {
  /* For webkit-based browsers */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  /* For other browsers */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Payment Detail */

.payment-card-border {
  border: 1px solid rgb(218, 217, 217);
  border-radius: 5px;
}

.plans-card {
  margin-top: 20px !important;
}

.editorHeight {
  height: 200px !important;
}

/* WorkspaceProject / Filter Css / Accordion*/
.accordion-Summary {
  padding: 0px 10px;
  height: 40px !important;
  min-height: 0px;
}
.css-1dy2dt4-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px;
  margin-bottom: 6px !important;
}
.row-h-effect {
  padding: 0px 4px;
}
.row-h-effect:hover {
  background-color: #ebf8f2 !important;
  cursor: pointer;
  border-radius: 6px;
}
/* checkbox --- */
.check-box-filter {
  padding: 9px 0px !important;
}
.check-box-filter:hover {
  background-color: transparent !important;
}
.check-box-filter.Mui-checked:hover {
  background-color: transparent !important;
}

/* Card ------ */

/* Font-classes */

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-8 {
  font-size: 8px !important;
}

/*  */
.task-top-header {
  /* display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
}
